import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { DrupalNodeSolutionBase } from "../../types";
import { isDrupalNodeSolution7thEdition } from "../../utils";

interface QuestionProps {
  node: DrupalNodeSolutionBase;
}

const Question = ({ node }: QuestionProps) => {
  return (
    <div className="bg-white rounded-t-xl pr-2 pl-0 py-4 grid grid-cols-12 items-center">
      <div
        className="self-start md:self-center col-span-2 text-center font-bold 
      text-4xl xs:text-6xl md:text-9xl lg:text-8xl xl:text-9xl text-neutral-100"
      >
        Q
      </div>
      <div
        className="col-span-10 prose-p:mb-2 prose prose-ol:list-none prose-ol:pl-4 text-neutral-700 prose-li:my-1
      prose-h1:text-neutral-700 prose-h1:font-normal prose-h1:text-base prose-h1:mb-0 prose-h1:leading-7"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: node.fields.fieldQuestionProcessedKatex,
          }}
        ></div>
        {isDrupalNodeSolution7thEdition(node) &&
          node.relationships.field_question_image.map((item, index) => {
            return (
              <div key={index} className="mb-2">
                <GatsbyImage
                  image={getImage(item.localFile)}
                  alt={node.field_question_image[index].alt}
                />
                {node.fields.fieldQuestionImageCaptionKatex[index] && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: node.fields.fieldQuestionImageCaptionKatex[index],
                    }}
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Question;
