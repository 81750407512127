import React, { useEffect, useMemo, useState } from "react";
import { graphql, HeadProps, PageProps } from "gatsby";
import "../styles/katex.cssnano.css";
import VideoArea from "../features/solution/media-chrome/VideoArea";
import Menu from "../features/solutionMenu/Menu";
import {
  DrupalNodeSolutionBase,
  DrupalComment,
  ChapterTerm,
  ChapterNode,
} from "../types";
import { EDITION_7TH } from "../constants";
import Question from "../features/solution/Question";
import Answer from "../features/solution/Answer";
import Heading from "../features/solution/Heading";
import Navbar from "../features/navbar/Navbar";
import SectionTitle from "../components/SectionTitle";
import CommentParent from "../features/comment/CommentParent";
import Seo from "../features/seo/Seo";
import { useCurrentUserQuery } from "../features/user/userApiSlice";
import { useGetCommentsQuery } from "../features/comment/commentApiSlice";
import Footer from "../features/footer/Footer";
import { chapterSections7thEdition } from "../features/solutionMenu/utils";
import { comments7thEdition } from "../features/comment/utils";
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import * as seoUtils from "../features/seo/utils";
import { JsonLd } from "react-schemaorg";

const CommentCreateFormLazy = React.lazy(
  () => import("../features/comment/CommentCreateForm")
);

export type DrupalNodeSolution7thEdition = DrupalNodeSolutionBase & {
  comment_node_7th_edition_video: {
    cid: number;
    last_comment_timestamp: number;
    comment_count: number;
  };
  field_question: {
    value: string;
  };
  field_quick_answer: {
    value: string;
  };
  field_question_for_meta: string;
  field_answer_for_meta: string;
  field_question_image: {
    alt: string;
  }[];
  fields: {
    fieldQuestionImageCaptionKatex?: string[];
  };
  relationships: {
    comment__comment_node_7th_edition_video: DrupalComment[];
    field_question_image?: {
      localFile: FileNode;
    }[];
    field_7th_edition_chapter: ChapterTerm & {
      relationships: {
        node__7th_edition_video: ChapterNode[];
        taxonomy_term__7th_edition_section: {
          field_section_name: string;
          field_section_number: string;
          drupal_internal__tid: number;
          weight: number;
          relationships: {
            node__7th_edition_video: {
              drupal_internal__nid: number;
              field_duration: number;
              field_problem: number;
              path: {
                alias: string;
              };
            }[];
          };
        }[];
      };
    };
  };
};

interface Solution7thEditionData {
  node7thEditionVideo: DrupalNodeSolution7thEdition;
}

const Solution7thEdition = (props: PageProps<Solution7thEditionData>) => {
  const node = props.data.node7thEditionVideo;
  const { data: currentUser } = useCurrentUserQuery();
  const { data: commentsJsonapi, isFetching: isFetchingComments } =
    useGetCommentsQuery({
      contentType: "7th_edition_video",
      uuid: node.drupal_id,
    });
  const sections = useMemo(
    () => chapterSections7thEdition(node),
    [node.drupal_id]
  );
  const comments = useMemo(
    () => comments7thEdition(node, commentsJsonapi),
    [isFetchingComments, commentsJsonapi, node]
  );

  const isSSR = typeof window === "undefined";

  return (
    <>
      <Navbar isSolution />
      <div className="bg-neutral-100 h-full min-h-screen">
        <div className="max-w-8xl mx-auto">
          <Menu
            sections={sections}
            chapterTerm={node.relationships.field_7th_edition_chapter}
            node={node}
            edition={EDITION_7TH}
          />
          <div className="lg:ml-[28rem] px-4">
            <Heading node={node} />
            <Question node={node} />
            <Answer node={node} />
            <div className="flex justify-center my-8">
              <VideoArea
                node={node}
                chapterNodes={
                  node.relationships.field_7th_edition_chapter.relationships
                    .node__7th_edition_video
                }
              />
            </div>
            {!currentUser?.is_authenticated &&
              !node.field_sample &&
              node.field_transcript && (
                <div className="my-8">
                  <SectionTitle
                    backgroundStyle="white"
                    title="Video Transcript"
                  />
                  <p
                    dangerouslySetInnerHTML={{ __html: node.field_transcript }}
                  ></p>
                </div>
              )}
            {comments?.length > 0 && (
              <div id="section-heading--comments" className="my-8">
                <SectionTitle backgroundStyle="white" title="Comments" />
              </div>
            )}
            {comments?.map((comment) => {
              return (
                <CommentParent
                  key={comment.parent.drupal_internal__cid}
                  comment={comment}
                  contentType="7th_edition_video"
                />
              );
            })}
            <div className="my-8">
              {!isSSR && (
                <React.Suspense fallback={<div />}>
                  <CommentCreateFormLazy
                    contentType="7th_edition_video"
                    node={node}
                  />
                </React.Suspense>
              )}
            </div>
          </div>
          <div className="lg:ml-[28rem] lg:mr-4">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution7thEdition;

export const Head = ({ location, data }: HeadProps<Solution7thEditionData>) => {
  const node = data.node7thEditionVideo;
  const { quiz, webpage7thEdition } = seoUtils;

  return (
    <>
      <Seo
        title={`Giancoli 7th Edition, Chapter ${node.relationships.field_7th_edition_chapter.field_chapter_number}, Problem ${node.field_problem}`}
        pathname={location.pathname}
        description={seoDescription(node)}
      />
      <JsonLd item={quiz(node, location.pathname)} />
      <JsonLd item={webpage7thEdition(node, location.pathname)} />
    </>
  );
};

const seoDescription = (node: DrupalNodeSolution7thEdition) => {
  const maxChars = 160;
  const cleaned = node.field_question_for_meta;
  if (cleaned.trim().length <= maxChars) {
    return cleaned.trim();
  }
  const subStr = cleaned.slice(0, maxChars);
  return subStr.slice(0, subStr.lastIndexOf(" "));
};

export const query = graphql`
  query getSolution7thEdition($path__alias: String) {
    node7thEditionVideo(path: { alias: { eq: $path__alias } }) {
      drupal_internal__nid
      field_s3_filename
      field_s3_folder
      field_sample
      field_transcript
      created(formatString: "X")
      field_duration
      field_question {
        value
      }
      field_quick_answer {
        value
      }
      field_question_for_meta
      field_answer_for_meta
      title
      drupal_id
      fields {
        fieldQuestionProcessedKatex
        fieldQuickAnswerProcessedKatex
        fieldQuestionImageCaptionKatex
      }
      field_problem
      field_question_image {
        alt
      }
      comment_node_7th_edition_video {
        cid
        last_comment_timestamp
        comment_count
      }
      relationships {
        field_question_image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 350)
            }
          }
        }
        comment__comment_node_7th_edition_video {
          drupal_id
          relationships {
            uid {
              name
              drupal_internal__uid
            }
            entity_id {
              drupal_id
            }
          }
          changed(formatString: "X")
          comment_body {
            processed
            value
          }
          fields {
            commentBodyProcessedKatex
          }
          pid {
            drupal_internal__target_id
          }
          drupal_internal__cid
          thread
        }
        field_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 720, placeholder: TRACED_SVG)
            }
          }
        }
        videoThumbnailFixed: field_thumbnail {
          localFile {
            childImageSharp {
              resize(width: 640) {
                src
              }
            }
          }
        }
        field_webvtt {
          localFile {
            publicURL
          }
        }
        field_7th_edition_chapter {
          drupal_internal__tid
          field_chapter_number
          name
          field_thumbnail {
            alt
          }
          description {
            value
          }
          relationships {
            solutionMenuThumbnail: field_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 64, height: 64)
                }
              }
            }
            node__7th_edition_video {
              field_sample
              title
              field_problem
              path {
                alias
              }
            }
            taxonomy_term__7th_edition_section {
              field_section_name
              field_section_number
              drupal_internal__tid
              weight
              relationships {
                node__7th_edition_video {
                  drupal_internal__nid
                  field_duration
                  field_problem
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
